import { useLoaderData } from "@remix-run/react";
import DEFAULT_LOGO from "./aktiv.png";
import { DefaultShopSlugLoaderResponse } from "~/routes/_shopSlug/types";
import { DefaultShopIdLoaderResponse } from "~/routes/shops/$shopId/types";

export default function ShopLogo({ className }: { className?: string }) {
  const { pageIsEmbedded, shopData } = useLoaderData<DefaultShopSlugLoaderResponse | DefaultShopIdLoaderResponse>();
  const logoImageUrls = shopData.userInterfaceSetting.logoImageDerivatives;
  const navBarLogoWebp = logoImageUrls?.webp?.navbar?.toString() || DEFAULT_LOGO;
  const navBarLogoPng = logoImageUrls?.png?.navbar?.toString() || DEFAULT_LOGO;

  if (pageIsEmbedded) return null;

  return (
    <picture className={`cursor-pointer h-10 my-2.5 ${className}`}>
      <source media="(min-width: 0px)" type="image/webp" srcSet={navBarLogoWebp} />
      <source media="(min-width: 0px)" type="image/png" srcSet={navBarLogoPng} />
      <img src={navBarLogoPng} alt="Shop logo" className="w-auto h-full" />
    </picture>
  );
}
